<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <el-form :inline="true" :model="keepDeclareQuery" class="demo-form-inline" @keyup.native.enter="onSubmit">
          <el-form-item label="时间">
            <el-date-picker
                v-model="keepDeclareQuery.createdTime"
                align="right"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="类型">
            <el-select v-model="keepDeclareQuery.status" placeholder="请选择">
              <el-option label="请选择" value=""></el-option>
              <el-option label="已驳回" value="0"></el-option>
              <el-option label="已通过" value="1"></el-option>
              <el-option label="审核中" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit" icon="el-icon-search">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table
          :data="keepDeclareList"
          style="width: 100%;"
          v-loading="loading"
          :header-cell-style="{'text-align':'center'}">
        <!--        <el-table-column-->
        <!--            type="selection"-->
        <!--            width="55"-->
        <!--            :selectable="checkSelectTale"-->
        <!--            align="center">-->

        <!--        </el-table-column>-->
        <el-table-column
            fixed
            prop="incomeMoney"
            label="收入"
            width="80"
            align="center">
        </el-table-column>
        <el-table-column
            fixed
            prop="costMoney"
            label="成本"
            width="80"
            align="center">
        </el-table-column>
        <el-table-column
            fixed
            prop="taxMoney"
            label="纳税"
            width="80"
            align="center">
        </el-table-column>
        <el-table-column
            prop="status"
            label="状态"
            align="center">
          <template slot-scope="scope">
            <el-tag type="danger" size="small" v-if="scope.row.status === 0">已驳回</el-tag>
            <el-tag type="success" size="small" v-if="scope.row.status === 1">已通过</el-tag>
            <el-tag size="small" v-if="scope.row.status === 2">审核中</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            prop="createdAt"
            label="日期"
            align="center">
        </el-table-column>
        <el-table-column
            prop="invoiceUrl"
            label="发票"
            align="center">
          <template slot-scope="scope">
            <el-image
                v-if="scope.row.invoiceUrl"
                style="width: 80px; height: auto"
                :src="scope.row.invoiceUrl"
                @click="imgPreview(scope.row.invoiceUrl)"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
            prop="rejectReason"
            label="备注(驳回信息)"
            align="center">
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            align="center">
          <template slot-scope="scope">
            <el-button-group>
              <el-button type="success" @click="checkClick(scope.row)">查看</el-button>
              <el-button type="warning" v-if="scope.row.status===0" @click="updateClick(scope.row.id,1)">重新提交</el-button>
              <el-button type="danger" v-if="scope.row.status===2" @click="updateClick(scope.row.id,0)">撤销申请</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination style="margin-top: 10px"
                     @size-change="sizeChange"
                     @current-change="currentChange"
                     :current-page.sync="keepDeclareQuery.page"
                     :page-size="keepDeclareQuery.size"
                     :total="keepDeclareQuery.total"
                     background
                     layout="prev, pager, next">
      </el-pagination>
    </el-card>
    <el-drawer
        :destroy-on-close="true"
        :visible.sync="keepDeclareShow"
        :with-header="false"
        size="50%">
      <keep-declare :keepDeclare="keepDeclare"></keep-declare>
    </el-drawer>
  </div>
</template>

<script>
import {getKeepDeclarePageList, updKeepDeclare} from "@/api/declare/declare";
import { ImagePreview } from 'vant';
import KeepDeclare from "@/views/pc/taxPayment/keepDeclare";
import VantMessage from "@/utils/vantMessageUtils";
import MessageUtils from "@/utils/elementMessageUtils";

export default {
  name: "taxPaymentList",
  data() {
    return {
      keepDeclareShow:false,
      keepDeclare:[],
      loading: false,
      keepDeclareList: [],
      imagePreview:[],
      keepDeclareQuery: {
        createdTime: '',
        status: '',
        page: 1, //第几页
        size: 9, //每页查询几条数据
        total: 0, // 总条数
      },
    }
  },
  components:{
    KeepDeclare,
  },
  methods: {
    onSubmit() {
      this.keepDeclareQuery.page = 1;
      this.getKeepDeclarePageList();
    },
    checkClick(obj) {
      this.keepDeclare = obj;
      this.keepDeclareShow = true;
    },
    updateClick(keepDeclareId,keepDeclareType) {
      let text = keepDeclareType === 0?'你确定要撤销申请吗？':'你确定要重新提交吗？'
      MessageUtils.confirmMsg(text).then(async resolve => {
        if (resolve) {
          this.loading = true;
          let res = await updKeepDeclare({
            keepDeclareType,
            keepDeclareId
          })
          this.loading = false;
          if (res) {
            MessageUtils.successMsg("操作成功!")
            await this.getKeepDeclarePageList();
          }
        }
      })
    },
    sizeChange(val) {
      this.keepDeclareQuery.page = val;
      this.getKeepDeclarePageList();
    },
    currentChange(val) {
      this.keepDeclareQuery.page = val;
      this.getKeepDeclarePageList();
    },
    async getKeepDeclarePageList() {
      let res = await getKeepDeclarePageList(this.keepDeclareQuery);
      if (res) {
        this.keepDeclareList = res.keepDeclareList;
      }
    },
    imgPreview(url) {
      this.imagePreview=[];
      this.imagePreview.push(url);
      ImagePreview(this.imagePreview);
    }
  },
  mounted() {
    this.getKeepDeclarePageList();
  }
}
</script>

<style scoped>

</style>
