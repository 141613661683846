import request from "@/api/request";

/**
 * 添加记账记录
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function addKeepAccounts(params) {
    return await request.post("/keep/addKeepAccounts",params)
}

/**
 * 查询记账记录 详情
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function getKeepAccounts(params) {
    return await request.post("/keep/getKeepAccounts",params)
}

/**
 * 删除记账记录
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function delKeepAccounts(params) {
    return await request.post("/keep/delKeepAccounts",params)
}

/**
 * 修改记账-删除记账图片记录
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function delKeepAccountsFile(params) {
    return await request.post("/keep/delKeepAccountsFile",params)
}

/**
 * 修改记账
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function updKeepAccounts(params) {
    return await request.post("/keep/updKeepAccounts",params)
}

/**
 * 修改记账合同
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function updKeepAccountsContract(params) {
    return await request.post("/keep/updKeepAccountsContract",params)
}

/**
 * pc查询所有记账记录
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function getKeepAccountsPageList(params) {
    return await request.post("/keep/getKeepAccountsPageList",params)
}

/**
 * 查询合同地址
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function getContractById(params) {
    return await request.post("/keep/getContractById",params)
}
