<template>
  <div>
    <el-card class="box-card" :clerk="keepDeclare" >
      <el-descriptions class="margin-top" title="费用信息" :column="2" border >
        <el-descriptions-item labelStyle="width:100px" >
          <template slot="label">
            <i class="iconfont icon-income-o"></i>
            收入
          </template>
          {{ keepDeclare.incomeMoney }}
        </el-descriptions-item>
        <el-descriptions-item labelStyle="width:100px">
          <template slot="label">
            <i class="iconfont icon-icon_xinyong_xianxing_jijin-186"></i>
            纳税
          </template>
          {{ keepDeclare.taxMoney}}
        </el-descriptions-item>
        <el-descriptions-item >
          <template slot="label">
            <i class="el-icon-mobile-phone"></i>
            日期
          </template>
          {{ keepDeclare.createdAt }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="iconfont icon-chengbenguanli"></i>
            成本
          </template>
          {{ keepDeclare.costMoney }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-help"></i>
            状态
          </template>
          <el-tag type="danger" size="small" v-if="keepDeclare.status === 0">已驳回</el-tag>
          <el-tag type="success" size="small" v-if="keepDeclare.status === 1">已通过</el-tag>
          <el-tag  size="small" v-if="keepDeclare.status === 2">已提交</el-tag>
        </el-descriptions-item>
        <el-descriptions-item v-if="keepDeclare.status === 0">
          <template slot="label">
            <i class="iconfont icon-shenpibohui"></i>
            驳回原因
          </template>
          {{ keepDeclare.rejectReason }}
        </el-descriptions-item>
        <el-descriptions-item :span="2" labelStyle="width:100px">
          <template slot="label">
            <i class="iconfont icon-fapiao"></i>
            发票
          </template>
          <el-image :src="keepDeclare.invoiceUrl"  v-if="keepDeclare.invoiceUrl" style="width: 100px;height: auto;margin-left: 10px" :preview-src-list="invoiceUrl" ></el-image>
        </el-descriptions-item>
      </el-descriptions>
      <el-table
          :data="keepAccountsList"
          style="width: 100%;"
          v-loading="loading"
          :header-cell-style="{'text-align':'center'}">
        <el-table-column
            fixed
            prop="paymentType"
            label="收支方式"
            width="80"
            align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.paymentType === 0">收入</span>
            <span v-if="scope.row.paymentType === 1">支出</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="feeTypeName"
            label="费用类型"
            align="center">
        </el-table-column>
        <el-table-column
            prop="money"
            label="金额"
            align="center">
        </el-table-column>
        <el-table-column
            prop="keepTimeDay"
            label="日期"
            align="center">
        </el-table-column>
        <el-table-column
            prop="status"
            label="状态"
            align="center">
          <template slot-scope="scope">
            <el-tag type="danger" size="small" v-if="scope.row.status === 0">未提交</el-tag>
            <el-tag type="success" size="small" v-if="scope.row.status === 1">已提交</el-tag>
            <el-tag size="small" v-if="scope.row.status === 2">已纳税</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            prop="contractName"
            label="合同"
            align="center">
          <template slot-scope="scope">
            <el-popover
                placement="right"
                trigger="click">
              <ul>
                <li v-for="item in contract" :key="item.id">
                  <el-link type="primary" slot="reference" @click="checkContractById(item.fileUrl)">{{ item.contractName }}</el-link>
                </li>
              </ul>
              <el-link type="primary" slot="reference" @click="getContractById(scope.row.contractId)">{{ scope.row.contractName }}</el-link>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination style="margin-top: 10px"
                     @size-change="sizeChange"
                     @current-change="currentChange"
                     :current-page.sync="keepAccountsQuery.page"
                     :page-size="keepAccountsQuery.size"
                     :total="keepAccountsQuery.total"
                     background
                     layout="prev, pager, next">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import {getContractById, getKeepAccountsPageList} from "@/api/cost/cost";

export default {
  name: "keepDeclare",
  data() {
    return {
      contract:[],
      loading:false,
      invoiceUrl:[],
      keepAccountsList:[],
      keepAccountsQuery: {
        keepDeclareId:'',
        page: 1, //第几页
        size: 9, //每页查询几条数据
        total: 0, // 总条数
      },
    }
  },
  props:{
    keepDeclare:{
      type:Object
    }
  },
  methods:{
    sizeChange(val) {
      this.keepAccountsQuery.page = val;
      this.getKeepAccountsPageList();
    },
    currentChange(val) {
      this.keepAccountsQuery.page = val;
      this.getKeepAccountsPageList();
    },
    async getKeepAccountsPageList() {
      this.loading = true;
      this.keepAccountsQuery.keepDeclareId = this.keepDeclare.id
      let res = await getKeepAccountsPageList(this.keepAccountsQuery);
      this.loading = false;
      if (res) {
        this.keepAccountsList = res.keepAccountsList
        this.keepAccountsQuery.total = res.count
      }
    },
    async getContractById(contractId) {
      let res = await getContractById({contractId});
      if (res.length > 0) {
        this.contract = res
      }
    },
    checkContractById(url) {
      window.open(url)
    }
  },
  mounted() {
    this.invoiceUrl.push(this.keepDeclare.invoiceUrl)
    this.getKeepAccountsPageList();
  }
}
</script>

<style scoped>

</style>
